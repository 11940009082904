<template>
  <Layout :items="items">
    <PageHeader title="Profile">
      <template #button>
        <div class="flex-shrink-0 text-end">
          <b-button
            variant="outline-primary"
            class="me-2"
            data-bs-toggle="modal"
            href="#sign-out-modal"
          >
            Sign Out
          </b-button>
          <b-button
            variant="primary"
            data-bs-toggle="modal"
            href="#profile-form-modal"
          >
            Edit Profile
          </b-button>
        </div>
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-lg-12">
        <b-card-body
          class="rounded"
          style="border: 1px #CED4DA solid;"
        >
          <table class="table-borderless">
            <tbody>
              <tr>
                <td class="py-1">
                  Name
                </td>
                <td class="py-1 px-3">
                  :
                </td>
                <td class="py-1">
                  {{ profile.name }}
                </td>
              </tr>
              <tr>
                <td class="py-1">
                  Role
                </td>
                <td class="py-1 px-3">
                  :
                </td>
                <td class="py-1">
                  {{ organizationRole }}
                </td>
              </tr>
              <tr>
                <td class="py-1">
                  Email
                </td>
                <td class="py-1 px-3">
                  :
                </td>
                <td class="py-1">
                  {{ profile.email }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </div>
      <div class="col-lg-12">
        <SectionHeader title="My Organization" />
        <div>
          <b-table
            class="table thead-light"
            primary-key="id"
            :items="profile.organizations"
            :fields="tableFields"
            responsive
            bordered
            stacked="sm"
            no-local-sorting
            no-sort-reset
          >
            <template #cell(name)="row">
              <router-link
                :to="`/organization/${row.item.id}`"
                class="link-item-table custom-link"
              >
                {{ row.item.name }}
              </router-link>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-modal
      id="sign-out-modal"
      title="Sign Out"
      centered
      hide-footer
      size="sm"
    >
      <div class="my-2">
        Are you sure to sign out ?
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div class="col-6 pe-2">
          <a href="/logout">
            <b-button
              variant="soft-primary"
              data-bs-dismiss="modal"
              class="w-100"
            >
              Yes
            </b-button>
          </a>
        </div>
        <div class="col-6 ps-2">
          <div class="d-flex">
            <b-button
              variant="primary"
              class="w-100"
              data-bs-dismiss="modal"
            >
              No
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <ProfileFormModal
      :profile="profile"
      @update-data="updateData"
    />
  </Layout>
</template>

<script>
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/PageHeader";

  import ProfileFormModal from './ProfileFormModal.vue';

  import { mapState } from 'vuex';

  export default {
    components: {
      Layout,
      PageHeader,
      ProfileFormModal
    },
    data() {
      return {
        title: "My Profile",
        items: [{
            text: "Profile",
            href: "/profile",
          },
        ],
        tableFields: [
          { 
            key: 'name',
            label: 'Name',
            class: 'text-break fit',
            sortable: true,
          },
          { 
            key: 'description',
            label: 'Description'
          }
        ]
      };
    },
    computed: {
      ...mapState('auth', ['profile']),
      ...mapState('organization', ['selectedOrganization']),

      organizationRole() {
        if (this.profile.is_superadmin) {
          return 'Superadmin'
        }
        if (this.selectedOrganization) {
          const userOrganization = this.profile.organizations.map(item => item.pivot)
          const selectedOrganization = userOrganization.find(item => item.organization_id == this.selectedOrganization)
          return selectedOrganization.organization_role ?? ''
        }
        return ''
      },
    },
    methods: {
      updateData() {
        this.$store.dispatch('auth/getProfile');
      },
      signOut() {
        this.$router.push('/logout');
      }
    },
  };
</script>